import config from 'config'

import { t } from 'tools'

import icon from './play-store-icon.svg'
import c from './style.module.css'

function getMobileOperatingSystem () {
    const userAgent = navigator.userAgent

    if ( /android/i.test( userAgent )) {
        return 'Android'
    }

    if ( /iPad|iPhone|iPod/.test( userAgent )) {
        return 'iOS'
    }

    return 'unknown'
}

export function ButtonStore () {
    const isAndroid = getMobileOperatingSystem() === 'Android'

    if ( !isAndroid ) { return null }

    return (
        <a
            className={c.a}
            href={config.links.android}
            rel="noopener noreferrer"
            target="_blank"
        >
            <img
                alt=""
                className={c.img}
                height={24}
                src={icon}
                width={25}
            />

            { t( 'global.getAndroid' ) }
        </a>
    )
}
