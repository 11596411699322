import { ICard } from '../../types'

import { AccountItem } from './AccountItem'
import c               from './style.module.css'

export function AccountList ({ cards }: { cards: ICard[] | null }) {


    return (
        <ul className={c.list}>
            {cards && cards.map( card => (
                <li key={card.id} className={c.item}>
                    <AccountItem card={card} />
                </li>
            ))}
        </ul>
    )
}
