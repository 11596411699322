import { ButtonSignOut } from './ButtonSignOut'
import { Footer }        from './Footer'
import { LinkAndLogo }   from './LinkAndLogo'
import { Selector } from './Selector'
import { buildUserIdUrl } from './Selector/AccountList/AccountItem'
import c from './style.module.css'
import { ICard } from './types'

interface SelectPageProps {
    cards: ICard[] | null,
    onSignOut: () => void
}

export function ProfileListPage ({ cards, onSignOut }: SelectPageProps ) {
    const filteredCards = ( cards || []).filter( card => !card.is_lite && !card.is_archive && !card.parent )

    // redirect in case of the single profile
    if ( filteredCards.length === 1 ) {
        const targetUrl = buildUserIdUrl( filteredCards[ 0 ])

        window.location.replace( targetUrl )
    }

    return (
        <>
            <main className={c.main}>
                <LinkAndLogo />

                <div className={c.container}>
                    <Selector cards={cards} />
                    <ButtonSignOut onClick={onSignOut} />
                </div>
            </main>

            <div className={c.container}>
                <Footer />
            </div>
        </>
    )
}
