import React, { ReactElement } from 'react'

import { Footer } from './Footer'
import { FormCode } from './Forms/FormCode'
import { FormEmail } from './Forms/FormEmail'
import { LinkAndLogo } from './LinkAndLogo'
import { MiddleStuff } from './MiddleStuff'
import { TopStuff } from './TopStuff'
import c from './style.module.css'

function FormPage ({ children }: { children: ReactElement }) {
    return (
        <>
            <main className={c.main}>
                <LinkAndLogo />

                <div className={c.container}>
                    <TopStuff />
                    {children}
                    <MiddleStuff />
                </div>
            </main>

            <div className={c.container}>
                <Footer />
            </div>
        </>
    )
}

export function FormEmailPage () {
    return (
        <FormPage>
            <FormEmail />
        </FormPage>
    )
}

export function FormCodePage () {
    return (
        <FormPage>
            <FormCode />
        </FormPage>
    )
}

