import React, { useEffect, useRef, useState } from 'react'

import { t } from '../../../tools'

import { ListItems } from './ListItems'
import globe         from './globe.png'
import c             from './style.module.css'

export function SelectorLanguage () {
    const [ isChecked, setIsChecked ] = useState( false )
    const listRef = useRef<HTMLUListElement | null>( null )

    const selectorText = t( 'global.changeLanguage' )
    // this is the current language, just for the sake of the example
    // const selectorText = t( 'global.language' )

    const toggleCheck = () => {
        setIsChecked( !isChecked )
    }

    const handleClickOutside = ( event: MouseEvent ) => {
        const targetNode = event.target as Node
        if ( targetNode && listRef.current && !listRef.current?.contains( targetNode )) {
            setIsChecked( false )
        }
    }

    useEffect(() => {
        if ( isChecked ) {
            document.addEventListener( 'mousedown', handleClickOutside )
        } else {
            document.removeEventListener( 'mousedown', handleClickOutside )
        }

        return () => {
            document.removeEventListener( 'mousedown', handleClickOutside )
        }
    }, [ isChecked ])

    return (
        <div className={c.selector}>
            <input
                checked={isChecked}
                className={c.checkbox}
                id="toggle"
                type="checkbox"
                onChange={toggleCheck}
            />

            <label className={c.label} htmlFor="toggle">
                <img
                    alt=""
                    className={c.img}
                    height={20}
                    src={globe}
                    width={20}
                />

                {selectorText}
            </label>

            {isChecked && (
                <ul ref={listRef} className={c.list}>
                    <ListItems toggleCheck={toggleCheck} />
                </ul>
            )}
        </div>
    )
}
