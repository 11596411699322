import TabeeAuth                      from '@tabee/frontend-auth'
import axios                          from 'axios'
import config                         from 'config'
import { AxiosEvent, TErrors }        from 'models'
import React, { useEffect, useState } from 'react'

import { Center, TabeeLoader }      from 'components'
import { Error } from 'pages'
import { check, extract, generate } from 'tools'

import { ProfileListPage } from '../../version-minimalistic/ProfileListPage'
import { SignInPage } from '../../version-minimalistic/SignInPage'

const
    Main = () => {
        if ( process.env.REACT_APP_BRAND ) {
            // todo this way we can import the styles from the brand
            // import( `./${process.env.REACT_APP_BRAND}/main.scss` )
            import( '../../version-minimalistic/styling' )
        }

        const
            api = process.env.REACT_APP_API,
            token = localStorage.getItem( 'sso-token' ),
            account = localStorage.getItem( 'sso-account' ),

            { redirect, originalRedirect, signOut, urlParams } = extract.params(),
            // used in the original logic, which reloads the application
            // addContact = urlParams.get( 'addcontact' ),
            // contactType = urlParams.get( 'contact_type' ),
            returnURL = urlParams.get( 'return_url' ),
            download = urlParams.get( 'download' ),
            allowed = redirect && check.allowed( redirect ),

            [ checking, $checking ] = useState( true ),
            [ view, $view ] = useState( false ),
            [ cards, $cards ] = useState( null ),

            onFail = () => {
                $checking( false )
            },

            onFailAndClear = () => {
                localStorage.removeItem( 'sso-token' )
                localStorage.removeItem( 'sso-account' )

                onFail()
            },

            onSignOut = () => {
                localStorage.removeItem( 'sso-token' )
                localStorage.removeItem( 'sso-account' )

                TabeeAuth.logOut().then(() => {
                    urlParams.delete( 'signout' )
                    window.location.href = `${window.location.origin}?${urlParams.toString()}`
                })
            },

            onIsAuthorized = ( tokenParam?: string | null ) => {
                if ( originalRedirect && !tokenParam ) {
                    onSuccess( token, localStorage.getItem( 'sso-account' ))
                } else {
                    const totoken =  tokenParam || token
                    axios.get(
                        api + '/api/cards',
                        config.axiosConfig( totoken )
                    ).then(( event: AxiosEvent ) => {
                        $cards( event.data.results )
                        $checking( false )
                        $view( true )
                    })
                }
            },

            onInit = () => {
                if ( signOut ) {
                    onSignOut()
                } else if ( token ) {
                    axios.get(
                        api + '/api/users',
                        config.axiosConfig( token )
                    ).then(( event: AxiosEvent ) => {
                        ( event.status === 200 )
                            ? event.data.id === account
                                ? onIsAuthorized()
                                : onSignOut()
                            : onFailAndClear()
                    }).catch( onFailAndClear )
                } else {
                    onFail()
                }
            }

        function onSuccess ( token: string | null, account: string | null ) {
            localStorage.setItem( 'sso-token', token || '' )
            localStorage.setItem( 'sso-account', account ?? '' )

            const
                re = decodeURIComponent( redirect ),
                delim = re.indexOf( '?' ) > -1 ? '&' : '?',
                // used in the original logic, which reloads the application
                // params = () => generate.joinparams([
                //     addContact && `addcontact=${addContact}`,
                //     ( addContact && contactType ) && `contact_type=${contactType}`,
                //     returnURL && `return_url=${returnURL}`,
                //     download && `download=${download}`
                // ], '?' ),
                reparams = () => generate.joinparams([
                    returnURL && `return_url=${returnURL}`,
                    download && `download=${download}`
                ])

            if ( redirect === '/' ) {
                onIsAuthorized( token )
                // original logic, which reloads the application
                // window.location.href = `${redirect}${params()}`
            } else {
                window.location.href = `${decodeURIComponent( redirect ?? '' )}${delim}user=${account}&hash=${token}${reparams()}`
            }
        }

        useEffect(() => {
            onInit()
        }, [])

        if ( checking ) {
            return (
                <Center>
                    <TabeeLoader visible />
                </Center>
            )
        }

        if ( view ) {
            return (
                // For the reference.
                // <Profile cards={cards} onSignOut={onSignOut} />
                <ProfileListPage cards={cards} onSignOut={onSignOut} />
            )
        }

        return (
            <>
                {
                    allowed
                        // For the reference.
                        // ? <Login onSuccess={onSuccess} />
                        ? <SignInPage onSuccess={onSuccess} />
                        : <Error error={TErrors.invalidRedirect} payload={{ redirect }} />
                }
            </>
        )
    }

export default Main
