import TabeeAuth from '@tabee/frontend-auth'
import { FormEvent, useContext, useState } from 'react'

import config from '../../config'
import { strings, t } from '../../tools'
import { SignInContext } from '../SignInPage'

import { ButtonAction } from './ButtonAction'
import { InputEmail } from './InputEmail'
import { Form } from './index'
import c from './style.module.css'

const isAvoidedHost = ( host: string ) =>
    config.dismissHosts.indexOf( host.toLowerCase()) > -1

export function FormEmail () {
    const [ valid, setValid ] = useState( false )
    const ctx = useContext( SignInContext )

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const codeMode = ( event: Record<string, any> ) => {
        ctx?.$locked( false )

        if ( !event.status || event.status >= 300 ) {
            const
                msg = event.message === '<'
                    ? t( 'errors.somethingWentWrong' )
                    : (
                        event.message === 'Not found'
                            ? t( 'errors.emailNotFound' )
                            : typeof event.message === 'string'
                                ? event.message
                                : event.message?.message || t( 'errors.somethingWentWrong' )
                    )

            ctx?.$message?.( msg )

            if ( strings.toKey( msg.split( ',' )[ 0 ]) === 'alreadySent' ) {
                ctx?.$mode?.( 'code' )
            }
        } else {
            ctx?.$mode?.( 'code' )
        }

    }

    const requestCode = ( e: FormEvent<HTMLFormElement> ) => {
        e.preventDefault()

        if ( ctx?.locked || !ctx?.email ) { return }

        ctx?.$locked( true )
        ctx?.$message( '' )

        const
            query = new URLSearchParams( window.location.search ),
            redirect = query.get( 'redirect_uri' ),
            returnURL = query.get( 'return_url' ),
            download = query.get( 'download' )

        TabeeAuth
            .signIn2fa({
                email:  ctx?.email,
                source: {
                    // eslint-disable-next-line camelcase
                    redirect_uri: redirect || ( isAvoidedHost( window.location.host ) ? '' : `${window.location.host}/auth/sso` ),
                    // eslint-disable-next-line camelcase
                    return_url:   returnURL || ( window.location.pathname !== '/login' ? window.location.pathname : '' ),
                    download:     download || null
                }
            })
            .then( codeMode )
            .catch( codeMode )
    }

    return (
        <Form onSubmit={requestCode}>
            <InputEmail setValid={setValid} />
            {ctx?.message && <p className={c.error}>{ctx?.message}</p>}
            <ButtonAction disabled={!valid || !!ctx?.locked} />
        </Form>
    )
}
