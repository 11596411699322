import { useContext } from 'react'

import { t } from 'tools'

import { SignInContext } from '../../SignInPage'

import c from './style.module.css'

function getText ( mode?: string, locked?: boolean ) {
    let text = mode === 'code' ? t( 'auth.enterCode' ) : t( 'auth.getCode' )
    if ( locked ) {
        text = mode === 'code' ? t( 'auth.loginSigningIn' ) : t( 'auth.gettingCode' )
    }

    return text
}

export function ButtonAction ({ disabled }: { disabled: boolean }) {
    const ctx = useContext( SignInContext )

    return (
        <button
            className={c.button}
            disabled={disabled || ctx?.locked}
            type="submit"
        >
            {getText( ctx?.mode, ctx?.locked )}
        </button>
    )
}
