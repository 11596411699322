import TabeeAuth from '@tabee/frontend-auth'
import { FormEvent, useContext, useState } from 'react'

import { t } from 'tools'

import { SignInContext } from '../SignInPage'

import { ButtonAction } from './ButtonAction'
import { InputCode } from './InputCode'
import { Form } from './index'
import c from './style.module.css'

interface LoginData {
    data: object,
    message: string,
    status: boolean,
    token: string,
    user: {
        id: string
    }
}

export function FormCode () {
    const [ valid, setValid ] = useState( false )
    const ctx = useContext( SignInContext )

    // code form
    const handleSuccess = ( event: LoginData ) => {
        ctx?.onSuccess( event.token, event.user.id )
    }

    const handleError = ( event: LoginData ) => {
        if ( event?.data && typeof event.data === 'object' ) {
            ctx?.$message( Object.values( event.data )[ 0 ])
        } else {
            ctx?.$message( 'Unknown error' )
        }
    }

    const onResult = ( event: LoginData ) => {
        if ( event.token ) {
            handleSuccess( event )
        } else {
            handleError( event )
        }
    }

    const signIn = ( e: FormEvent<HTMLFormElement> ) => {
        e.preventDefault()

        if ( ctx?.locked || !ctx?.email || !ctx?.code ) { return }

        ctx?.$locked( true )
        ctx?.$message( '' )

        TabeeAuth.signInCode({
            email: ctx?.email,
            code:  ctx?.code
        })
            .then(( event: LoginData ) => {
                onResult( event )
            })
            .catch(( event: LoginData ) => {
                ctx?.$locked( false )
                ctx?.$code( '' )
                ctx?.$message(
                    !event.message
                    || event.message === 'Error'
                    || event.message === 'Not found'
                        ? t( 'errors.wrongCode' )
                        : event.message
                )
            })
    }

    return (
        <Form onSubmit={signIn}>
            <InputCode setValid={setValid} />
            {ctx?.message && <p className={c.error}>{ctx?.message}</p>}
            <ButtonAction disabled={!valid || !!ctx?.locked} />
        </Form>
    )
}
