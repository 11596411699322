import React from 'react'

import { t } from 'tools'

import svg from './icon.svg'
import c   from './style.module.css'

interface Props {
    className: string;
    href: string;
    onClick: ( e: React.MouseEvent<HTMLAnchorElement, MouseEvent> ) => void;
}

export function LinkBack ({ className, href, onClick }: Props ) {
    return (
        <a className={`${c.link} ${className}`} href={href} onClick={onClick}>
            <img alt="" className={c.img} src={svg} />
            {t( 'global.back' )}
        </a>
    )
}
