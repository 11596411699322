// import { useRoute } from 'preact-iso'

import React, { useContext } from 'react'

import { SignInContext } from '../SignInPage'

import { LinkBack }  from './LinkBack'
import { TabeeLogo } from './TabeeLogo'
import c             from './style.module.css'

// const routeBase = '/sign-in'
// const routes = [ '/email', '/code', '/select' ]

export function LinkAndLogo () {
    // const route = useRoute()
    // const routeIndex = routes.indexOf( route.path )
    // const routeIsFurther = routeIndex === 1 || routeIndex === 2

    const ctx = useContext( SignInContext )
    const routeIsFurther = ctx?.mode === 'code' // || ctx?.mode === 'select'

    // fixme сейчас следуем логике без роутинга, как в прежнем дизайне
    // fixme надо роутинг на основе параметров view и allowed (или во что-нибудь переименованное), см. tb-front-sso/src/pages/Main/Main.tsx Это если связвыать его с SelectPage. Внутри формы роутинг работает
    // const routeIndex = 2
    // const routeIsFurther = false

    const onBackClick = ( e: React.MouseEvent<HTMLAnchorElement, MouseEvent> ) => {
        e.preventDefault()
        ctx?.$mode( 'email' )
        // clear the error message
        ctx?.$message( '' )
    }

    if ( routeIsFurther ) {
        return (
            <div className={`${c.container} ${c.further}`}>
                <LinkBack
                    className={c.further}
                    // href={`${routeBase}${routes[ routeIndex - 1 ]}`}
                    href="#"
                    onClick={onBackClick}
                />

                <TabeeLogo className={c.further} />
            </div>
        )
    }

    return (
        <div className={c.container}>
            <TabeeLogo />
        </div>
    )
}
