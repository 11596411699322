import { useContext } from 'react'

import { t } from 'tools'

import { SignInContext } from '../../SignInPage'

import c from './style.module.css'

export function Checkbox () {
    const ctx = useContext( SignInContext )
    const setRemember = () => ctx?.$remember( !ctx?.remember )

    return (
        <div className={c.container}>
            <input
                checked = { ctx?.remember }
                className={c.checkbox}
                disabled = { ctx?.locked }
                id="keep-signed"
                name="keep-signed"
                type="checkbox"
                onChange={setRemember}
            />

            <label className={c.label} htmlFor="keep-signed">
                {t( 'auth.keepSignIn' )}
            </label>
        </div>
    )
}
