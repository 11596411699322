import { t } from 'tools'

import { ICard } from '../types'

import { AccountList } from './AccountList'
import c               from './style.module.css'

export function Selector ({ cards }: { cards: ICard[] | null }) {
    return (
        <>
            <h1 className={c.heading}>{t( 'sso.selectIdentity' )}</h1>
            <p className={c.text}>{t( 'sso.multipleIdentities' )}</p>
            <AccountList cards={cards} />
        </>
    )
}
