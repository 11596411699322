// import { useRoute } from 'preact-iso'

import { useContext } from 'react'

import { t } from 'tools'

import { SignInContext } from '../SignInPage'

import c from './style.module.css'

export function TopStuff () {
    // const route = { path: '/email' }
    // const route = useRoute()
    // const string = route.path === '/code' ? t( 'auth.legendCode' ) : t( 'auth.legendEmail' )

    const ctx = useContext( SignInContext )
    const mode = ctx?.mode
    const string = mode === 'code' ? t( 'auth.legendCode' ) : t( 'auth.legendEmail' )

    return (
        <>
            <h1 className={c.heading}>{t( 'auth.loginTitle' )}</h1>
            <p className={c.legend}>{string}</p>
        </>
    )
}
