import React, { createContext, useEffect, useMemo, useState } from 'react'

import config from '../config'

import { FormCodePage, FormEmailPage } from './FormPages'

interface LoginProps {
    onSuccess: ( token: string, account: string ) => void
}

interface SignInContextProps {
    mode: string;
    email: string;
    code: string;
    remember: boolean;
    locked: boolean;
    message: string;
    $mode: React.Dispatch<React.SetStateAction<string>>;
    $email: React.Dispatch<React.SetStateAction<string>>;
    $code: React.Dispatch<React.SetStateAction<string>>;
    $remember: React.Dispatch<React.SetStateAction<boolean>>;
    $locked: React.Dispatch<React.SetStateAction<boolean>>;
    $message: React.Dispatch<React.SetStateAction<string>>;
    onSuccess: ( token: string, account: string ) => void
}

export const SignInContext = createContext<SignInContextProps | null>( null )

export const SignInPage = ({ onSuccess }: LoginProps ) => {
    const // выбор формы, in use
        [ mode, $mode ] = useState( 'email' ),
        // email input value
        [ email, $email ] = useState( '' ),
        // code input value
        [ code, $code ] = useState( '' ),
        // запоминание пользователя, in use, but it seems like it's just a status of the checkbox
        [ remember, $remember ] = useState( localStorage.getItem( config.rememberKey ) === 'true' ),
        // фриз формы
        [ locked, $locked ] = useState( false ),
        // сообщение об ошибке
        [ message, $message ] = useState( '' )

    useEffect(() => {
        localStorage.setItem( config.rememberKey, remember.toString())
    }, [ remember ])

    const contextValue = useMemo(() => ({
        mode,
        $mode,
        email,
        $email,
        code,
        $code,
        remember,
        $remember,
        locked,
        $locked,
        message,
        $message,
        onSuccess,
    }), [ mode, email, code, remember, locked, message ])

    return (
        <SignInContext.Provider value={contextValue}>
            {mode === 'code' ? <FormCodePage /> : <FormEmailPage />}
        </SignInContext.Provider>
    )
}
