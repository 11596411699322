import React from 'react'

import { i18next } from 'tools'

import c from './style.module.css'

interface ListItemsProps {
    toggleCheck: () => void
}

const langs = i18next.options.resources || {}

export const ListItems = ({ toggleCheck }: ListItemsProps ) => {
    const setLang = ( lang: string ) => () => {
        i18next.changeLanguage( lang )
        toggleCheck()
    }

    return (
        <>
            {Object.keys( langs ).map(( language ) => {
                const langData = langs[ language ]

                return (
                    <li key={language} className={c.item}>
                        <button
                            className={`${c.button} ${i18next.language === language ? c.active : ''}`}
                            type="button"
                            onClick={setLang( language )}
                        >
                            {langData.main.global.language}
                        </button>
                    </li>
                )
            })}
        </>
    )
}
