import { FormEvent, ReactNode, useContext } from 'react'

import { SignInContext } from '../SignInPage'

import { Checkbox } from './Checkbox'
import c from './style.module.css'

interface FormProps {
    children: ReactNode,
    onSubmit?: ( e: FormEvent<HTMLFormElement> ) => void
}

export const Form = ({ children, onSubmit }: FormProps ) => {
    const ctx = useContext( SignInContext )
    const mode = ctx?.mode
    const setMode = ctx?.$mode

    // todo remove, this is kinda stub
    const go = ( e: FormEvent<HTMLFormElement> ) => {
        e.preventDefault()

        if ( !setMode ) { return }

        if ( mode === 'email' ) {
            setMode( 'code' )
        } else if ( mode === 'code' ) {
            // setMode( 'select' )
            setMode( 'email' )
        }
    }

    return (
        <form action="" className={c.form} onSubmit={onSubmit ?? go}>
            {children}
            {/* this one holds remembering logic, common for the both forms */}
            <Checkbox />
        </form>
    )
}

