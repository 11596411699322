/* eslint-disable react/no-danger */
// import { useRoute } from 'preact-iso'

import { useContext } from 'react'

import { t } from '../../tools'
import { SignInContext } from '../SignInPage'

import { ButtonStore } from './ButtonStore'
import c from './style.module.css'

export function MiddleStuff () {
    // const route = { path: '/email' }
    // const route = useRoute()
    // const buttonText = route.path === '/code' ? t( 'auth.enterCode' ) : t( 'auth.getCode' )

    const ctx = useContext( SignInContext )
    const mode = ctx?.mode
    const buttonText = mode === 'code' ? t( 'auth.enterCode' ) : t( 'auth.getCode' )
    const termsUrl = t( 'global.termsUrl' )
    const privacyUrl = t( 'global.privacyPolicyURL' )

    const string = t( 'auth.agreement', {
        buttonText,
        termsUrl,
        privacyUrl
    })

    return (
        <>
            <p
                className={c.disclaimer}
                dangerouslySetInnerHTML={{ __html: string }}
            />

            <ButtonStore />
        </>
    )
}
