import config from 'config'
import { SyntheticEvent } from 'react'

import { extract, generate } from 'tools'

import stub from '../../../tabee/profile-placeholder-neutral.svg'
import { ICard } from '../../../types'

import right    from './right.svg'
import stubIcon from './stub-account-icon.svg'
import c        from './style.module.css'

export function buildUserIdUrl ( card?: ICard ) {
    if ( !card ) { return '#' }

    const
        token = localStorage.getItem( 'sso-token' ),
        user = localStorage.getItem( 'sso-account' ),
        { urlParams } = extract.params(),
        contact = urlParams.get( 'addcontact' ),
        contactType = urlParams.get( 'contact_type' ),
        download = urlParams.get( 'download' ),
        returnURL = urlParams.get( 'return_url' ),
        params = ( card: ICard ) => generate.joinparams([
            contact && `addcontact=${contact}`,
            ( contact && contactType ) && `contact_type=${contactType}`,
            ( returnURL && decodeURIComponent( returnURL ) !== '/' ) && `return_url=${returnURL}&use_card=${card.id}`,
            download && `download=${download}`
        ], '&' )

    return `${process.env.REACT_APP_ID}/u/${card.id}?forced=${user}&hash=${token}${params( card )}`
}

function AccountItemStub () {
    const str = {
        title: 'Work email',
        email: 'elezkich@example.com',
    }

    return (
        <a className={c.a} href='#'>
            <img alt="" className={c.img} src={stubIcon} />

            <div className={c.container}>
                <p className={c.title}>{str.title}</p>
                <p className={c.email}>{str.email}</p>
            </div>

            <img alt="" className={c.icon} src={right} />
        </a>
    )
}

const onImageError = ( event: SyntheticEvent<HTMLImageElement> ) => {
    const target = event.target as HTMLImageElement
    target.src = stub
    // alternative variant
    // target.style.display = 'none'
}

export function AccountItem ({ card }: { card?: ICard}) {
    // static fallback
    if ( !card ) { return <AccountItemStub /> }

    // if the card is internal, don't show it
    if ( card.parent ) { return null }

    const email = card.email?.contact ?? 'No data'

    const mediaHost = config.images.media

    // todo refine the data and fallbacks
    const avatar = card.company_owner ? card.company_owner.square_logo : card.owner_user.avatar
    // const fallback = card.owner_user.avatar
    const profileIcon = `${mediaHost}/${avatar}`

    const title =  card.company ?
        card.position ?
            `${card.position} @ ${card.company}` :
            card.company :
        'No data'

    return (
        <a className={c.a} href={buildUserIdUrl( card )}>
            <img
                alt=""
                className={c.img}
                src={profileIcon ?? stub}
                onError={onImageError}
            />

            <div className={c.container}>
                <p className={c.title}>{title}</p>
                <p className={c.email}>{email}</p>
            </div>

            <img alt="" className={c.icon} src={right} />
        </a>
    )
}
