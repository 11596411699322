import { t } from '../../tools'

import c from './style.module.css'

export function ButtonSignOut ({ onClick }: { onClick: () => void }) {
    return (
        <button className={c.button} type="button" onClick={onClick}>
            {t( 'admin.signOut' )}
        </button>
    )
}
