import React, { useContext } from 'react'

import { SignInContext } from '../../SignInPage'
import { InputProps } from '../../types'

import c from './style.module.css'

export function InputCode ({ setValid }: InputProps ) {
    const ctx = useContext( SignInContext )

    const handleInputChange = ( event: React.FormEvent<HTMLInputElement> ) => {
        const target = event.target as HTMLInputElement
        setValid(( target ).checkValidity())
        // clear the error message
        ctx?.$message( '' )

        ctx?.$code(( target.value || '' ).trim().toLowerCase())
    }

    const containerClassName = ctx?.message ? `${c.container} ${c.error}` : c.container
    const inputClassName = ctx?.message ? `${c.input} ${c.error}` : c.input

    return (
        <>
            <div className={containerClassName}>
                {/* OTP form best practices: https://web.dev/articles/sms-otp-form */}
                <input
                    required
                    autoComplete="one-time-code"
                    className={inputClassName}
                    disabled={ctx?.locked}
                    inputMode="numeric"
                    maxLength={6}
                    name="code"
                    pattern="\d{6}"
                    type="text"
                    onInput={handleInputChange}
                />
            </div>
        </>
    )
}
