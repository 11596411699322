import React, { useContext } from 'react'

import { t } from 'tools'

import { SignInContext } from '../../SignInPage'
import { InputProps } from '../../types'

import c from './style.module.css'

export function InputEmail ({ setValid }: InputProps ) {
    const ctx = useContext( SignInContext )

    const handleInputChange = ( event: React.FormEvent<HTMLInputElement> ) => {
        const target = event.target as HTMLInputElement
        // client-side email validation
        setValid(( target ).checkValidity())
        // clear the error message
        ctx?.$message( '' )

        ctx?.$email(( target.value || '' ).trim().toLowerCase())
    }

    const containerClassName = ctx?.message ? `${c.container} ${c.error}` : c.container
    const inputClassName = ctx?.message ? `${c.input} ${c.error}` : c.input

    return (
        <>
            <div className={containerClassName} data-placeholder={t( 'auth.email' )}>
                {/* A spaced placeholder is needed for the tricky CSS selector */}
                <input
                    required
                    autoComplete = "email"
                    className={inputClassName}
                    disabled={ctx?.locked}
                    name="email"
                    placeholder=" "
                    type="email"
                    onChange={handleInputChange}
                />
            </div>
        </>
    )
}
