import moment from 'moment/moment'

import { t } from '../../tools'

import { SelectorLanguage } from './SelectorLanguage'
import c                    from './style.module.css'

export function Footer () {
    return (
        <footer className={c.footer}>
            <p className={c.copy}>
                { t( 'global.copyright', { year: moment().format( 'YYYY' ) }) }
            </p>

            <SelectorLanguage />
        </footer>
    )
}
